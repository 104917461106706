import * as React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { useConfig, useFooterHeight } from 'store/state'
export const Footer = () => {
    const [config] = useConfig()
    const [, setFooterActions] = useFooterHeight()
    const ref = React.useRef(null)
    React.useEffect(() => {
        setFooterActions.setState(ref.current.clientHeight)
    })
    return (
        <Box
            ref={ref}
            sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: 'secondary.light',
                paddingTop: '1rem',
                paddingBottom: '1rem',
            }}
        >
            <Container maxWidth='lg'>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography color='textSecondary' variant='h5' sx={{ fontWeight: 'bold' }}>
                        {config.business_display_name}
                    </Typography>
                </Box>
                <Grid container spacing={0}>
                    <Grid item xs={12} md>
                        <Typography color='textSecondary' variant='body1' align='center'>
                            {config.contact_phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md>
                        <Typography noWrap color='textSecondary' variant='body1' align='center' sx={{ fontWeight: 'bold' }}>
                            <div dangerouslySetInnerHTML={{
                                __html: config.contact_address.replace(', ', '<br />')
                            }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md>
                        <Typography color='textSecondary' variant='body1' align='center'>
                            {config.contact_email}
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography color='textSecondary' variant='body2' >
                        Copyright {`${new Date().getFullYear()}`} {config.business_official_name}, All Rights Reserved
                    </Typography>
                </Box>
            </Container>
        </Box >
    )
}

export default Footer
