import { createTheme } from '@mui/material'
import shadows from './shadows'
import typography from './typography'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
      '3xl': 1920,
      '4xl': 2560,
      '5xl': 3200,
    },
  },
  palette: {
    primary: {
      main: '#3399ff',
    },
    secondary: {
      main: '#ff9124',
    },
  },
  shadows,
  typography
})


export default theme
