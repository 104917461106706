import * as React from 'react'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'
import { Parallax } from 'react-parallax'
import { useConfig } from 'store/state'
import * as Content from 'Content'
import PageWrapper from 'Components/PageWrapper'
import Slide from 'Components/Transitions/Slide'
import ScrollTopFab from 'Components/ScrollTopFab'
import Empathy from 'Content/Definitions/Empathy'
import Security from 'Content/Definitions/Security'

const Component = () => {
  const [config] = useConfig()
  const containerRef = React.useRef(null)

  const videos = [
    {
      url: 'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/video/Cogs.mp4',
      color: 'primary.dark',
      message: <>
        <Typography variant='h2'>
          <strong>Custom Built</strong>
        </Typography>
        <Typography variant='h2'>
          <strong>Business Software</strong>
        </Typography>
      </>
    },
    {
      url: 'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/video/Coding.mp4',
      color: 'white',
      message: <>
        <Typography variant='h2'>
          <strong>Business Software</strong>
        </Typography>
        <Typography variant='h2'>
          <strong>Done Your Way</strong>
        </Typography>
      </>
    },
    {
      url: 'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/video/2022-11-20_17-06-27_000.mov',
      color: 'white',
      message: <>
        <Typography variant='h2'>
          <strong>Reach Your Customers</strong>
        </Typography>
        <Typography variant='h2'>
          <strong>Faster</strong>
        </Typography>
      </>
    },
  ]
  const random = Math.floor(Math.random() * videos.length)

  return (<>
    <ScrollTopFab />
    <PageWrapper>
      <Box
        sx={{
          color: videos[random].color,
          position: 'absolute',
          mt: '25%',
          ml: '25%'
        }} >
        {videos[random].message}
      </Box>
      <video
        width='100%'
        autoPlay
        muted
        loop>
        <source
          type='video/mp4'
          src={videos[random].url}
        />
        {config.business_display_name} is a software development company that specializes
        in building custom software solutions for businesses.
      </video>
      <Box
        sx={{
          color: 'black',
          p: 10
        }}
      >

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Empathy />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='h5' sx={{
              textAlign: 'justify',
              textJustify: 'inter-word',
              m: 5
            }}>
              If you are in the market for a new service provider, the company you select
              to work with should have empathy for you and your business.
              <br /><br />
              Problems will arise. How they are handled is what matters.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Parallax
        blur={10}
        bgImage='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/floating-datacenter+(2).jpg'
        bgImageAlt='the cat'
        strength={500}>
        <Box sx={{ height: 1500 }}>
          <Box sx={{
            width: '90%',
            background: 'white',
            padding: 10,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            <Content.IsNewSoftwareWorthTheExpense />

          </Box>
        </Box>
      </Parallax>
      <Box
        sx={{
          color: 'black',
          p: 10
        }}
      >

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant='h5' sx={{
              textAlign: 'justify',
              textJustify: 'inter-word',
              m: 5
            }}>
              In 2019, we made a decision to stop all development to re-architect all of our software from the ground up to
              take advantage of the latest cloud technology and to make our products more secure.
              Now they are easier to scale, maintain, extend, and recover from disaster,
              which leads to a substantially lower cost to your business.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Security />
          </Grid>

        </Grid>
      </Box>
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/digital-datacenter+(1).jpg'}
        bgImageAlt='the dog'
        strength={900}
      >
        <Box
          style={{ height: '1500px' }}
          ref={containerRef}
        >
          <Box sx={{
            width: '90%',
            background: 'white',
            padding: 10,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            <Content.BenefitsSoftwareEasilyChange />
          </Box>
        </Box>
      </Parallax>
      <Box
        sx={{
          p: 10,
          overflow: 'hidden',
        }}
      >
        <Slide
          stayActive
          timeout={2000}
          direction='right'
          container={containerRef.current}
        >
          <Typography variant='h4'>
            Scalability
          </Typography>
          <Typography>
            Software that can scale as a business grows can help businesses avoid having to switch
            to new software or systems as they expand, reducing disruption and cost.
          </Typography>
        </Slide>
        <br />
        <Slide
          stayActive
          timeout={2000}
          direction='left'
          container={containerRef.current}
        >
          <Typography variant='h4'>
            Mobile access
          </Typography>
          <Typography>
            Software that can be accessed from mobile devices can help employees work remotely
            and stay connected, improving productivity and flexibility.
          </Typography>
        </Slide>
      </Box>
    </PageWrapper>
  </>
  )
}

export default Component