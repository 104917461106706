import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import Fade from 'Components/Transitions/Fade'

export default function MultiActionAreaCard() {
  return (
    <Fade
      timeout={3000}
      sensorProps={{
        partialVisibility: true,
      }}>
      <Card sx={{ maxWidth: 345, height: '100%' }}>
        <CardActionArea>
          <CardMedia
            component='img'
            image='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/_eccade0f-1f87-4db3-8c2c-c8cb71fc70ff.jpg'
            alt='Cloud DataCenter'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              IT Infrastructure
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We can help you build out your networking and infrastructure,
              cloud VPC resources, site to site VPNs, zero trust resources, and more.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary'>
            Share
          </Button>
        </CardActions>
      </Card>
    </Fade>
  )
}
