
import * as React from 'react'
import * as Icons from '@mui/icons-material'
import {
  Typography,
      List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'


export default function Component() {

  return <>
    <Typography variant='h5'>
      Is your sofware flexible?
    </Typography>
    <br />
    <Typography>
      Software that can easily change or adapt offers several benefits, including:
    </Typography>

    <List
      sx={{ width: '100%' }}
    >
      <ListItem disablePadding>

        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Flexibility: With software that can easily change, businesses can quickly respond to changes in the market, industry, or customer needs. This allows them to stay competitive and agile in a fast-paced environment.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Cost Savings: Software that can easily change can save businesses money by reducing the need for custom development or expensive upgrades. Instead, businesses can make changes in-house or work with the software provider to make adjustments as needed.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Improved User Experience: Software that can easily change can lead to a better user experience by allowing businesses to tailor the software to their specific needs. This can result in increased productivity, satisfaction, and adoption of the software.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Faster Time-to-Market: With software that can easily change, businesses can bring new products or services to market more quickly. This can help them stay ahead of the competition and capitalize on new opportunities.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Increased Innovation: Software that can easily change can foster a culture of innovation by allowing businesses to experiment with new ideas and quickly test and iterate on them.
          </ListItemText>
        </ListItemButton>
      </ListItem>

    </List>
    <Typography>
      Overall, software that can easily change can help businesses stay flexible, save money, improve the user experience, bring products to market faster, and drive innovation.
    </Typography>
  </>
}
