import * as React from 'react'
import {
  Box,
} from '@mui/material'
import { Parallax } from 'react-parallax'
import * as Content from 'Content'
import PageWrapper from 'Components/PageWrapper'
import ScrollTopFab from 'Components/ScrollTopFab'

const Component = () => {

  return (<>
    <ScrollTopFab />
    <PageWrapper>
      <Parallax
        blur={10}
        bgImage='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/floating-datacenter+(2).jpg'
        bgImageAlt='the cat'
        strength={500}>
        <Box sx={{ height: 500 }}>
          <Box sx={{
            background: 'white',
            padding: 20,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            <Content.BenefitsSoftwareEasilyChange />
          </Box>
        </Box>
      </Parallax>
      <Box sx={{ height: 200 }} />
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/digital-datacenter+(1).jpg'}
        bgImageAlt='the dog'
        strength={900}
      >

        <Box style={{ height: '1500px' }} >
          <Box sx={{
            background: 'white',
            margin: 1,
            padding: 2,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            <Content.BenefitsSoftwareEasilyChange />
          </Box>
        </Box>
      </Parallax>

    </PageWrapper>
  </>
  )
}

export default Component