import * as React from 'react'
import {
    Box,
    CssBaseline,
} from '@mui/material'
import AppBar from 'Components/AppBar'
import Footer from 'Components/Footer'
import {
    useHeaderHeight,
    useFooterHeight
} from 'store/state'

const Component = (props) => {
    const [headerHeight] = useHeaderHeight()
    const [footerHeight] = useFooterHeight()

    return (<>
        <CssBaseline />
        <AppBar />
        <Box
            id='page-spacer'
            sx={{
                mt: `${headerHeight}px`,
            }}
        />
        <Box
            id='page-wrapper'
            sx={{
                minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
            }}
        >
            {props.children}
        </Box>
        <Footer />
    </>
    )
}

export default Component