import * as React from 'react'

import * as Layouts from 'Layouts'
import * as Pages from 'Pages'

export const routes = [
  {
    path: '/',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'about', element: <Pages.AboutUs /> },
      { path: 'contact', element: <Pages.Contact /> },
      { path: 'home', element: <Pages.Home /> },
      { path: 'pricing', element: <Pages.Pricing /> },
      { path: 'products', element: <Pages.Product /> },
      { path: 'careers', element: <Pages.Careers /> },
      { path: 'services', element: <Pages.Services /> },
      { path: 'why-us', element: <Pages.ChooseUs /> },
      { path: 'elements', element: <Pages.Elements /> },

      { path: '/', element: <Pages.Home /> },
      { path: '404', element: <Pages.NotFound /> },
      { path: '*', element: <Pages.NotFound /> }
    ]
  }
]
