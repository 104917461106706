import * as React from 'react'
import {
  Box,
  Grid
} from '@mui/material'
import { Parallax } from 'react-parallax'
import { useConfig } from 'store/state'
import * as Content from 'Content'
import PageWrapper from 'Components/PageWrapper'
import ScrollTopFab from 'Components/ScrollTopFab'

import AdjunctDevelopers from './AdjunctDevelopers'
import BuildOperateTransfer from './BuildOperateTransfer'
import BusinessOperations from './BusinessOperations'
import BusinessSoftware from './BusinessSoftware'
import CloudComputing from './CloudComputing'
import ITInfrastructure from './ITInfrastucture'
import ScrumTeams from './ScrumTeams'
import SoftwareDevelopment from './SoftwareDevelopment'
import TechnicalTraining from './TechnicalTraining'

const displayProps = {
  xs: 12,
  sm: 6,
  md: 4,
}

const Component = () => {
  const [config] = useConfig()
  const containerRef = React.useRef(null)


  return (<>
    <ScrollTopFab />
    <PageWrapper >
      <Parallax
        blur={10}
        bgImage='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/floating-datacenter+(2).jpg'
        bgImageAlt='the cat'
        strength={500}>
        <Box sx={{ height: 500 }}>
          <Box sx={{
            background: 'white',
            padding: 5,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            {config.business_display_name} offers a variety of products and services to help businesses streamline
            operations and get new custom built software to market faster.
            We are a team of software developers, designers, and project managers who are passionate about building
            software and helping businesses grow. Your success is our success.
          </Box>
        </Box>
      </Parallax>
      <Box
        sx={{ m: 10 }}
        ref={containerRef}
      >
        <Grid container spacing={3}>
          <Grid item {...displayProps}>
            <AdjunctDevelopers />
          </Grid>
          <Grid item {...displayProps}>
            <BuildOperateTransfer />
          </Grid>
          <Grid item {...displayProps}>
            <BusinessOperations />
          </Grid>
          <Grid item {...displayProps}>
            <BusinessSoftware />
          </Grid>
          <Grid item {...displayProps}>
            <CloudComputing />
          </Grid>
          <Grid item {...displayProps}>
            <TechnicalTraining />
          </Grid>
          <Grid item {...displayProps}>
            <SoftwareDevelopment />
          </Grid>
          <Grid item {...displayProps}>
            <ScrumTeams />
          </Grid>
          <Grid item {...displayProps}>
            <ITInfrastructure />
          </Grid>
        </Grid>
      </Box>
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/digital-datacenter+(1).jpg'}
        bgImageAlt='the dog'
        strength={900}
      >
        <Box style={{ height: '1500px' }} >
          <Box sx={{
            width: '90%',
            background: 'white',
            padding: 10,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
          }}>
            <Content.CalculateRoiNewBusinessSoftware />
          </Box>
        </Box>
      </Parallax>
    </PageWrapper>
  </>
  )
}

export default Component