import * as React from 'react'
import * as Icons from '@mui/icons-material'
import {
  Typography,
      List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'


export default function Component() {

  return <>
    <Typography variant='h5'>
      How to Evaluate a New Software Purchase
    </Typography>
    <br />
    <Typography>
      Calculating ROI(Return on Investment) on new business software involves comparing the costs of the software with the potential benefits it can bring to the business.Here are the steps to follow:
    </Typography>

    <List
      sx={{ width: '100%' }}
    >
      <ListItem disablePadding>

        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Determine the Total Cost of Ownership (TCO) of the software: This includes the initial purchase cost of the software, implementation and integration costs, and ongoing maintenance and support costs.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Identify the potential benefits of the software: This may include increased productivity, reduced costs, improved customer satisfaction, increased revenue, or any other benefits specific to the software and the business.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Quantify the potential benefits: For each potential benefit, estimate the monetary value of the benefit over a specific period of time (e.g., one year).
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Calculate the net present value (NPV) of the software: Subtract the TCO from the total value of the potential benefits to calculate the NPV of the software.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Identify the potential benefits of the software: This may include increased productivity, reduced costs, improved customer satisfaction, increased revenue, or any other benefits specific to the software and the business.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Calculate the ROI: Divide the NPV by the TCO and multiply by 100 to calculate the ROI as a percentage.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText inset>
            For example, if a new software system costs $100,000 to purchase, implement, and maintain over the course of three years, but is expected to provide $150,000 in benefits over the same period, the NPV would be $50,000. If we divide the NPV by the TCO and multiply by 100, we get an ROI of 50%.
          </ListItemText>
        </ListItemButton>
      </ListItem>

    </List>
    <Typography>
      It's important to note that ROI calculations should be based on realistic estimates and assumptions, and should take into account the potential risks and uncertainties associated with implementing new software.

    </Typography>
  </>
}
