import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
)

const card = (
    <React.Fragment>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Word of the Day
            </Typography>
            <Typography variant="h5" component="div">
                em{bull}pa{bull}thy
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                noun
            </Typography>
            <Typography variant="body2">
                the ability to understand and share the feelings of another
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic', mt: '2em' }}>
                "most companies have no empathy for their customers and many even see them as a nuisance"
            </Typography>

        </CardContent>
        <CardActions>
            <Button size="small">Learn More</Button>
        </CardActions>
    </React.Fragment>
)

export default function OutlinedCard() {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">{card}</Card>
        </Box>
    )
}