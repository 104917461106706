import * as React from 'react'
import {
  Box,
} from '@mui/material'
import { Parallax } from 'react-parallax'
import { useConfig } from 'store/state'
import * as Content from 'Content'
import PageWrapper from 'Components/PageWrapper'
import Fade from 'Components/Transitions/Fade'
import ScrollTopFab from 'Components/ScrollTopFab'

const Component = () => {
  const [config] = useConfig()

  return (<>
    <ScrollTopFab />
    <PageWrapper>
      <Parallax
        blur={10}
        bgImage='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/floating-datacenter+(2).jpg'
        bgImageAlt='Floating Datacenter'
        strength={500}>
        <Box sx={{ height: 700 }}>
          <Fade stayActive timeout={3000}>
            <Box sx={{
              background: 'white',
              padding: 10,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}>
              {config.business_display_name} is a software development company that specializes in building custom software
              solutions for businesses.
              <br /><br />
              We are a team of software developers, designers, and project managers who are passionate about
              building software that helps businesses grow in their capacity to service their clients.
              <br /><br />
              We are now leveraging our 25 years of exerience as consultants in the software industry
              to build best-in-class ERP software solutions for businesses.
            </Box>
          </Fade>
        </Box>
      </Parallax>

      <Box sx={{ height: 20 }} />

      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/digital-datacenter+(1).jpg'}
        bgImageAlt='the dog'
        strength={900}
      >
        <Box style={{ height: '1500px' }} >
          <Fade
            stayActive
            timeout={3000}
            sensorProps={{ partialVisibility: true }}
          >
            <Box sx={{
              background: 'white',
              margin: 1,
              padding: 2,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}>
              <Content.BenefitsSoftwareEasilyChange />
            </Box>
          </Fade>
        </Box>
      </Parallax>

    </PageWrapper >
  </>
  )
}

export default Component