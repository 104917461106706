import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Icons from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import { useMenu, useHeaderHeight } from 'store/state'

import Logo from 'Components/Logo'
import ScreenSize from 'Components/ScreenSize'


const pages = [
  { display: 'Home', link: 'home' },
  // { display: 'Pricing', link: 'pricing' },
  { display: 'Who We Are', link: 'about' },
  { display: 'What We Do', link: 'services' },
  //{ display: 'How We Do It', link: 'products' },
  { display: 'Why Us?', link: 'why-us' },
  { display: 'Careers', link: 'careers' },
  { display: 'Get In Touch', link: 'contact' },
  //{ display: 'Community', link: 'community' },
]


function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export default function Component(props) {
  const [menu, menuActions] = useMenu()
  const [, setHeaderActions] = useHeaderHeight()

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const ref = React.useRef(null)

  React.useEffect(() => {
    setHeaderActions.setState(ref.current.clientHeight)
  })

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar sx={{ backgroundColor: 'white' }} ref={ref}>
          <Container maxWidth="xl" id='header-container'>
            <Toolbar disableGutters>
              <ScreenSize />
              <Logo display={{ xs: 'none', md: 'flex' }} />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  onClick={handleOpenNavMenu}
                >
                  <Icons.Menu />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >{pages.map((page) => {
                  const { display, link } = page
                  return (
                    <RouterLink
                      key={link}
                      style={{
                        //textDecoration: menu === link ? 'underline' : 'none',
                        //textDecorationColor: '#FFFAF0',
                        textDecoration: 'none',
                      }}
                      to={`/${link}`}
                      onClick={(e) => {
                        handleCloseNavMenu(e)
                        menuActions.setState(link)
                        window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                      }}
                    >
                      <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{display}</Typography>
                      </MenuItem>
                    </RouterLink>
                  )
                })}
                </Menu>
              </Box>
              <Logo
                display={{ xs: 'flex', md: 'none' }}
                sx={{ flexGrow: 1 }}
              />

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  ml: 5,
                  mt: 3
                }}>
                <Grid container spacing={2}>
                  {pages.map(({ display, link }) => {
                    return (
                      <Grid
                        sx={{ border: 0 }}
                        key={link}
                        item
                        xs={2}
                      >
                        <Button
                          component={RouterLink}
                          to={`/${link}`}
                          variant={link === 'contact' ? 'contained' : 'text'}
                          sx={{
                            my: 2,
                            display: 'block',
                            color: link === 'contact' ? 'white' : 'primary'
                          }}
                          onClick={(e) => {
                            handleCloseNavMenu(e)
                            menuActions.setState(link)
                            window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
                          }}
                        >
                          <Typography
                            variant='body1'
                            align="center"
                            sx={{
                              fontWeight: menu === link ? 'bold' : 'normal',
                            }}>
                            {display}
                          </Typography>
                        </Button>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </React.Fragment >
  )
}