import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import Fade from 'Components/Transitions/Fade'

export default function MultiActionAreaCard() {
  return (
    <Fade
      timeout={3000}
      sensorProps={{
        partialVisibility: true,
      }}>
      <Card sx={{ maxWidth: 345, height: '100%' }}>
        <CardActionArea>
          <CardMedia
            component='img'
            image='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/_112ec685-b90d-405e-8634-45a104c6dcc7.jpg'
            alt='Floating Datacenter'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              Business Operations
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Our ERP solution is best in class, built from the ground up
              using cutting edge cloud serverless architecture.
              Built for speed.
              Built for resilience.
              Built to scale.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary'>
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Fade>
  )
}
