import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import Fade from 'Components/Transitions/Fade'

export default function MultiActionAreaCard() {
  return (
    <Fade
      timeout={3000}
      sensorProps={{
        partialVisibility: true,
      }}>
      <Card sx={{ maxWidth: 345, height: '100%' }}>
        <CardActionArea>
          <CardMedia
            component='img'
            image='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/_0f651afd-c674-4cd9-a605-e7f98e33d53e.jpg'
            alt='Men In Lab Coats'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              Business Software
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We are masters of our craft. We have been building business software since 1999.
              We continuosly educate ourselves on new technology, best practices, and the
              continuously evolvingstate of the art.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary'>
            Share
          </Button>
        </CardActions>
      </Card>
    </Fade>
  )
}
