import * as React from 'react'
import * as Icons from '@mui/icons-material'
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'


export default function Component() {

  return <>
    <Typography variant='h5'>
      Is New Software Worth The Expense?
    </Typography>
    <br />
    <Typography>
      Determining whether buying new ERP(Enterprise Resource Planning) software is worth the expense depends on a variety of factors, including the current state of the business, the goals of the business, and the potential benefits of the new software.Here are some factors to consider:
    </Typography>

    <List
      sx={{ width: '100%' }}
    >
      <ListItem disablePadding>

        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Current System Limitations: If the current ERP system is outdated or lacks key features, upgrading to a new system may be necessary to keep up with business demands and remain competitive.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Scalability: If the business is growing rapidly or has plans to expand, a new ERP system that can accommodate growth may be necessary.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Streamlining Processes: If the business is struggling with inefficiencies, a new ERP system can streamline processes and improve overall productivity.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Integration: If the current system does not integrate well with other key business systems, a new ERP system may be necessary to improve connectivity and data sharing.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Cost-Benefit Analysis: It is important to weigh the cost of purchasing and implementing a new ERP system against the potential benefits, such as increased efficiency, productivity, and revenue.
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Icons.HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            Return on Investment (ROI): Calculating the potential ROI of a new ERP system can help determine if the investment is worth the expense.
          </ListItemText>
        </ListItemButton>
      </ListItem>

    </List>
    <Typography>
      In general, if a business is experiencing significant limitations or inefficiencies with its current ERP system, upgrading to a new system may be worth the expense in the long run. However, each business should carefully consider its unique circumstances before making a decision.
    </Typography>
  </>
}
