import * as React from 'react'
import {
	Box,
	Typography
} from '@mui/material'
import { Parallax } from 'react-parallax'
import PageWrapper from 'Components/PageWrapper'
import Fade from 'Components/Transitions/Fade'
import ScrollTopFab from 'Components/ScrollTopFab'

const Component = () => {

	return (<>
		<ScrollTopFab />
		<PageWrapper>
			<Parallax
				blur={{ min: -15, max: 15 }}
				bgImage={'https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/digital-datacenter+(1).jpg'}
				bgImageAlt='the dog'
				strength={900}
			>
				<Box style={{ height: '1500px' }} >
					<Box sx={{
						background: 'white',
						margin: 1,
						padding: 2,
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)'
					}}>
						<h1 style={{ color: 'green' }} >
							Geeks for Geeks (h1 tag)
						</h1>
						<h3>React MUI Typography (h3 tag)</h3>
						{/* wrapped by h2 tag and aligned in center */}
						<Typography variant='h1' align='center'>
							I am h1 default variant.
						</Typography>

						<Typography variant='h2' align='center'>
							I am h2 default variant.
						</Typography>

						{/* wrapped by h3 tag and color is primary */}
						<Typography variant='h3' color='primary'>
							I am h3 default variant.
						</Typography>

						{/* wrapped by h5 tag and have margin on top=5 */}
						<Typography variant='h5' sx={{ mt: 5 }}>
							I am h5 default variant.
						</Typography>

						{/* subtitle1 is wrapped by h6 tag */}
						<Typography variant='subtitle1'>
							I am subtitle1 default variant.
						</Typography>

						{/* subtitle2 is bold version of subtitle1
				and by default, wrapped by p h6
				but due to component prop='h6',
				now it is wrapped by 'p' */}
						<Typography variant='subtitle2' component='p'>
							I am subtitle2 default variant.
						</Typography>


						{/* wrapped by p tag */}
						<Typography variant='body1'>
							I am body1 default variant.
						</Typography>

						{/* wrapped by p tag but with smaller
				size compared to body1 variant */}
						<Typography variant='body2'>
							I am body2 default variant.
						</Typography>

						{/* wrapped by span ,all in caps and bold*/}
						<Typography variant='button'
							display='block' gutterBottom>
							Button text
						</Typography>

						{/* wrapped by span and monospace*/}
						<Typography variant='caption'
							display='block' gutterBottom>
							Caption text
						</Typography>

						{/* wrapped by span and all in caps */}
						<Typography variant='overline'
							display='block' gutterBottom>
							Overline text
						</Typography>
					</Box>
				</Box>
			</Parallax>

			<Fade
				sensorProps={{
					partialVisibility: false,
				}}
			>
				<h1 style={{ color: 'green' }} >
					Geeks for Geeks (h1 tag)
				</h1>
				<Box sx={{
					background: 'white',
					margin: 1,
					padding: 2,
				}}>
					<h1 style={{ color: 'green' }} >
						Geeks for Geeks (h1 tag)
					</h1>
					<h3>React MUI Typography (h3 tag)</h3>
					{/* wrapped by h2 tag and aligned in center */}
					<Typography variant='h1' align='center'>
						I am h1 default variant.
					</Typography>

					<Typography variant='h2' align='center'>
						I am h2 default variant.
					</Typography>

					{/* wrapped by h3 tag and color is primary */}
					<Typography variant='h3' color='primary'>
						I am h3 default variant.
					</Typography>

					{/* wrapped by h5 tag and have margin on top=5 */}
					<Typography variant='h5' sx={{ mt: 5 }}>
						I am h5 default variant.
					</Typography>

					{/* subtitle1 is wrapped by h6 tag */}
					<Typography variant='subtitle1'>
						I am subtitle1 default variant.
					</Typography>

					{/* subtitle2 is bold version of subtitle1
				and by default, wrapped by p h6
				but due to component prop='h6',
				now it is wrapped by 'p' */}
					<Typography variant='subtitle2' component='p'>
						I am subtitle2 default variant.
					</Typography>


					{/* wrapped by p tag */}
					<Typography variant='body1'>
						I am body1 default variant.
					</Typography>

					{/* wrapped by p tag but with smaller
				size compared to body1 variant */}
					<Typography variant='body2'>
						I am body2 default variant.
					</Typography>

					{/* wrapped by span ,all in caps and bold*/}
					<Typography variant='button'
						display='block' gutterBottom>
						Button text
					</Typography>

					{/* wrapped by span and monospace*/}
					<Typography variant='caption'
						display='block' gutterBottom>
						Caption text
					</Typography>

					{/* wrapped by span and all in caps */}
					<Typography variant='overline'
						display='block' gutterBottom>
						Overline text
					</Typography>
				</Box>
			</Fade>

		</PageWrapper>
	</>
	)
}

export default Component
