import { createSharedStoreHook } from 'shared-store-hook'

export const useConfig = createSharedStoreHook({
    initialState: {
        business_official_name: 'Tenspace LLC',
        business_display_name: 'Tenspace Software',
        contact_phone: '505-600-1741',
        contact_email: 'info@tenspacesoftware.com',
        contact_address: '603 S Public RD Unit 801, Lafayette, CO 80026',
    }
})