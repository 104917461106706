import Typography from '@mui/material/Typography'
export default function Logo(props) {
    return <>
        <Typography
            variant="h1"
            noWrap
            sx={{
                display: props.display,
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                overflow: 'visible',
                ...props.sx
            }}
        >
            Tenspace
        </Typography>
    </>
}
