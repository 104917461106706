import * as React from 'react'
import {
    Box
} from '@mui/material'

import context from 'context'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

export default function Settings(props) {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    if (process.env.NODE_ENV !== 'development') return

    return <>
        <Box
            sx={{
                fontVariant: 'small-caps',
                position: 'fixed',
                top: 0,
                left: 0,
                p: 1
            }}>
            {windowDimensions.width}x{windowDimensions.height}
            &nbsp;
            {context.breakpoints.map((breakpoint) => {
                return (
                    <Box
                        key={breakpoint}
                        sx={{
                            display: {
                                ...context.hidden,
                                [breakpoint]: 'inline-block'
                            }
                        }}>
                        {breakpoint}
                    </Box>
                )
            })}
        </Box >
    </>
}