import * as React from 'react'
import {
    Box,
    Fade as MuiFade,
} from '@mui/material'
import Sensor from 'react-visibility-sensor'

const Component = (props) => {
    let [active, setActive] = React.useState(false)
    let [permanent, setPermanent] = React.useState(false)
    let {
        stayActive,
        children,
        sensorProps,
        ...transitionProps
    } = props

    React.useEffect(() => {
        if (stayActive) {
            if (active) {
                setPermanent(true)
            }
        } else {
            setPermanent(active)
        }
    }, [active, stayActive])


    if (!React.isValidElement(children)) {
        children = <Box>{children}</Box>
    }

    return (<>
        <Sensor
            onChange={setActive}
            {...sensorProps}
        >
            <MuiFade
                in={permanent}
                {...transitionProps}
            >
                {children}
            </MuiFade>
        </Sensor >
    </>
    )
}

export default Component
