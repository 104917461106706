import * as React from 'react'
import {
  Typography,
  Box
} from '@mui/material'
import { Parallax } from 'react-parallax'
import PageWrapper from 'Components/PageWrapper'
import ScrollTopFab from 'Components/ScrollTopFab'
import {
  useHeaderHeight,
  useFooterHeight
} from 'store/state'

const Component = () => {
  const [headerHeight] = useHeaderHeight()
  const [footerHeight] = useFooterHeight()

  return (<>
    <ScrollTopFab />
    <PageWrapper>
      <Parallax
        blur={10}
        bgImage='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/_0346fe7a-614f-42a2-8711-4883f16022e4.jpg'
        bgImageAlt='the robot'
        strength={500}>
        <Box
          sx={{
            minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`
          }}>
          <Box
            sx={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              transform: 'translate3d(80%,-50%,0)'
            }} >
            <Typography variant='h4' color='secondary.light'>
              Tenspace
            </Typography>
            <Typography variant='h3'>
              We are here to serve you
            </Typography>
          </Box>
        </Box>
      </Parallax>
    </PageWrapper >
  </>
  )
}

export default Component