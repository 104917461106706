import * as React from 'react'
import {
  Box,
  Button,
  Grid,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import PageWrapper from 'Components/PageWrapper'
import ScrollTopFab from 'Components/ScrollTopFab'
import {
  useConfig,
} from 'store/state'

const Component = () => {
  const [config] = useConfig()

  function handleChange(e) {

  }
  function handleSubmit(e) {

  }

  return (<>
    <ScrollTopFab />
    <PageWrapper>
      <Container fixed sx={{ mb: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                pt: 5,
                pb: 5
              }}>
              <Typography variant='h3'>
                CONTACT US
              </Typography>
              Hello! How can we help you?
            </Box>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant='h5'>
                    Give Us a Call<br />
                  </Typography>
                  {config.contact_phone}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='h5'>
                    Corporate HQ<br />
                  </Typography>
                  <Typography
                    component='a'
                    href='https://goo.gl/maps/AHTMtmMw6JfLhpqz8'>
                    {config.contact_address}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} />
          <Grid item xs={12} lg={6}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d148097.76626577487!2d-105.19721839422455!3d39.99388020861574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bf4e5817d62f1%3A0x306771f4a85a2ac4!2s601%20S%20Public%20Rd%2C%20Lafayette%2C%20CO%2080026!5e0!3m2!1sen!2sus!4v1680841461498!5m2!1sen!2sus'
              width="100%"
              height='100%'
              style={{
                minHeight: '400px',
                border: 0
              }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              title="Map of location">
            </iframe>
          </Grid>
          <Grid item xs={12} lg={6}>
            <form
              id='contact-form'
              onSubmit={handleSubmit}
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    id='firstName'
                    label='First Name'
                    name='firstName'
                    onChange={handleChange('userName')}
                    margin='normal'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    id='lastName'
                    label='Last Name'
                    name='lastName'
                    onChange={handleChange('userName')}
                    margin='normal'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id='company'
                    label='Company'
                    name='company'
                    onChange={handleChange('company')}
                    margin='normal'
                    variant='standard'

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id='email'
                    label='Email Address'
                    name='email'
                    onChange={handleChange('email')}
                    margin='normal'
                    variant='standard'

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id='phone'
                    label='Phone Number'
                    onChange={handleChange('phone')}
                    margin='normal'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id='message'
                    label='Message'
                    name='message'
                    onChange={handleChange('message')}
                    margin='normal'
                    multiline
                    maxRows='4'
                    variant='standard'
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' spacing={3}>
                <Grid item >
                  <Button
                    type='reset'
                  //disabled={submitting || pristine}
                  >
                    RESET
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container >
    </PageWrapper >
  </>
  )
}

export default Component