import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import Fade from 'Components/Transitions/Fade'

export default function MultiActionAreaCard() {
  return (
    <Fade
      timeout={3000}
      sensorProps={{
        partialVisibility: true,
      }}>
      <Card sx={{ maxWidth: 345, height: '100%' }}>
        <CardActionArea>
          <CardMedia
            component='img'
            image='https://s3.us-west-2.amazonaws.com/www.tenspacesoftware.com/images/_c4dcaca5-47fa-40be-8874-698601e33a45.jpg'
            alt='robots studying'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div'>
              Technical Training
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We offer programs for executives to update their knowledge and understanding of modern
              technology. Management and supervisor training for senior developers including meetng with
              direct reports, developing and upskilling direct reports, and  well as code reviews.
              Classes for Agile product management, new developer skills,
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary'>
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Fade>
  )
}
