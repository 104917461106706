import * as React from 'react'
import {
  CssBaseline,
  ThemeProvider,
  responsiveFontSizes
} from '@mui/material'
import { HelmetProvider } from 'react-helmet-async'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import theme from './store/theme'
import { routes } from './store/routes'
import context from 'context'

const router = createBrowserRouter(routes)
function App() {
  return (
    <React.StrictMode>
      <ThemeProvider
        theme={responsiveFontSizes(theme, {
          breakpoints: context.breakpoints,
          factor: 10
        })}>
        <HelmetProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </HelmetProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
