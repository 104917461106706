import * as React from 'react'
import {
    Box,
    Slide as MuiSlide,
} from '@mui/material'
import Sensor from 'react-visibility-sensor'

const Component = (props) => {
    let [active, setActive] = React.useState(false)
    let [permanent, setPermanent] = React.useState(false)
    let {
        stayActive,
        children,
        sensorProps,
        ...transitionProps
    } = props

    React.useEffect(() => {
        if (stayActive) {
            if (active) {
                setPermanent(true)
            }
        } else {
            setPermanent(active)
        }
    }, [active, stayActive])

    if (!React.isValidElement(children)) {
        children = <Box>{children}</Box>
    }

    return (<>
        <Sensor
            onChange={setActive}
            {...sensorProps}
        >
            <Box>
                <MuiSlide
                    in={permanent}
                    {...transitionProps}
                >
                    {children}
                </MuiSlide>
            </Box>
        </Sensor >
    </>
    )

}

export default Component
